<script setup>
import {ref} from "vue";
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {MemberStore} from "@/uhas/store/member";

const router = useRouter();
const memberStore = MemberStore();

const {examResults} = storeToRefs(memberStore)

/**
 * Data
 */
const blockOverlay = ref(false);
const dtFormat = new Intl.DateTimeFormat('th-TH', { dateStyle: 'short', timeStyle: 'short' });

const score = ref({});

const chartData1 = ref({
	labels: [
		// 'การวิเคราะห์ปัญหาการเรียนรู้',
		// 'การออกแบบการวิจัยเพื่อพัฒนาการเรียนรู้',
		// 'การนำผลการวิจัยไปใช้ในการพัฒนาผู้เรียน',
		// 'การนำผลการวิจัยไปใช้ในการพัฒนาการเรียนการสอน',
		// "การแลกเปลี่ยนเรียนรู้ผลการวิจัย"
		"A", "B", "C", "D", "E"
	],
	datasets: [
		{
			label: "ขอบข่าย",
			fill: true,
			backgroundColor: 'rgba(255,99,132,0.2)',
			borderColor: 'rgba(255,99,132,1)',
			pointBackgroundColor: 'rgba(255,99,132,1)',
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgba(255,99,132,1)',
			data: []
		}
	]
});
const chartData2 = ref({
	labels: [
		// 'ความเข้าใจ',
		// 'นำไปใช้ประยุกต์ใช้',
		// 'วิเคราะห์',
		// 'สังเคราะห์/สร้างสรรค์',
		// 'ประเมิน'
		"A", "B", "C", "D", "E"
	],
	datasets: [
		{
			label: "ระดับ",
			backgroundColor: 'rgba(255,99,132,0.2)',
			borderColor: 'rgba(255,99,132,1)',
			pointBackgroundColor: 'rgba(255,99,132,1)',
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgba(255,99,132,1)',
			data: []
		}
	]
});
const chartOptions = ref({
	plugins: {
		legend: {
			labels: {
				color: '#495057',
				font: {
					size: 20,
					weight: "bold"
				},
				padding: 10,
			}
		}
	},
	scales: {
		r: {
			max: 5,
			min: 0,
			ticks: {
				stepSize: 1,
				// font: {
				// 	size: 20,
				// 	weight: "bold"
				// },
			},
			pointLabels: {
				color: '#495057',
				font: {
					size: 18,
					weight: "bold"
				},
			},
			// grid: {
			// 	color: '#ebedef',
			// },
			// angleLines: {
			// 	color: '#ebedef'
			// }
		}
	}
});

/**
 * Computed
 */


/**
 * Actions
 */
const viewChart = (data) => {
	chartData1.value.datasets[0].data = data.score.sp1
	chartData2.value.datasets[0].data = data.score.sp2

	score.value = data.score;
}
</script>

<template>
	<Dialog
		v-model:visible="blockOverlay"
		class="block-overlay"
		appendTo="self"
		:closeOnEscape="false"
		:showHeader="false"
		modal
	>
		<ProgressSpinner
			style="width:5vw;height:5vw"
			strokeWidth="8"
			fill="var(--surface-ground)"
			animationDuration=".5s"
		/>
	</Dialog>

	<template v-if="!examResults || examResults.length === 0">
		<Card>
			<template #content>
				<div class="text-center">
					<h4>คุณยังไม่มีประวัติการทำแบบทดสอบ</h4>

					<Button
						label="เริ่มทำแบบทดสอบ"
						class="mt-6"
						@click="router.push({name: 'exam'})"
					/>
				</div>
			</template>
		</Card>
	</template>

	<template v-else>
		<div>
			<DataTable :value="examResults" responsiveLayout="scroll">
				<template #header>
					<div class="table-header">
						รายงานผลการทำแบบทดสอบ
					</div>
				</template>
				<Column header="ครั้งที่" class="w-1">
					<template #body="{index}">
						{{ index + 1 }}
					</template>
				</Column>
				<Column header="เริ่มทำ">
					<template #body="{data}">
						{{ dtFormat.format(data.s) }}
					</template>
				</Column>
				<Column header="คะแนน">
					<template #body="{data}">
						<b v-if="!data.score">ทำข้อสอบไม่เสร็จ</b>
						<b v-else>{{ data.score.s }} / {{ data.score.f }}</b>
					</template>
				</Column>
				<Column header="ประเมิน" class="w-1">
					<template #body="{data}">
						<Button
							v-if="data.score"
							icon="pi pi-chart-bar"
							class="p-button-rounded p-button-text"
							@click="viewChart(data)"
						/>
					</template>
				</Column>
			</DataTable>

			<div class="grid">
				<div class="col-12 md:col-6 p-6 md:p-8 mx-auto">
					<Chart
						:data="chartData1"
						:options="chartOptions"
						type="radar"
					/>
					<div class="w-max max-w-full mx-auto mt-3">
						<span
							v-if="score.sp"
							class="block text-3xl my-4"
						>
							ข้อเสนอแนะ
						</span>
						<div>A: การวิเคราะห์ปัญหาการเรียนรู้</div>
						<p
							v-if="score.sp"
							class="pl-4"
						>
							<span
								v-if="score.sp[0][0] < 1"
								class="block"
							>
								- ควรทบทวนเนื้อหาเพื่อให้เกิดความเข้าใจเกี่ยวกับปัญหาการเรียนรู้ และวิธีการวิเคราะห์ปัญหา
							</span>
							<span
								v-if="score.sp[0][1] < 3"
								class="block"
							>
								- ควรฝึกการวิเคราะห์ปัญหาเพื่อให้สามารถนำไปใช้ในสถานการณ์ต่างๆ ได้อย่างถูกต้อง
							</span>
							<span
								v-if="score.sp[0][2] < 1"
								class="block"
							>
								- ควรศึกษากระบวนการวิเคราะห์ปัญหาเพื่อให้สามารถกำหนดรายละเอียดของปัญหาและออกแบบวิธีการแก้ปัญหาได้อย่างถูกต้อง
							</span>
						</p>

						<div>B: การออกแบบการวิจัยเพื่อพัฒนาการเรียนรู้</div>
						<p
							v-if="score.sp"
							class="pl-4"
						>
							<span
								v-if="score.sp[1][0] < 2"
								class="block"
							>
								- ควรทบทวนเนื้อหาเกี่ยวกับรูปแบบการวิจัยเพื่อให้เกิดความเข้าใจให้มากขึ้น
							</span>
							<span
								v-if="score.sp[1][1] < 3"
								class="block"
							>
								- ควรฝึกการออกแบบการวิจัยเพื่อให้สามารถนำไปใช้ในการพัฒนาการเรียนรู้ได้อย่างถูกต้อง
							</span>
							<span
								v-if="score.sp[1][3] < 1 || score.sp[1][4] < 1"
								class="block"
							>
								- ควรศึกษาขั้นตอนการออกแบบการวิจัยเพื่อให้สามารถวางแผนการแก้ปัญหาด้วยกระบวนการวิจัยได้อย่างถูกต้อง
							</span>
						</p>

						<div>C: การนำผลการวิจัยไปใช้ในการพัฒนาผู้เรียน</div>
						<p
							v-if="score.sp"
							class="pl-4"
						>
							<span
								v-if="score.sp[2][0] < 1"
								class="block"
							>
								- ควรทบทวนเนื้อหาเกี่ยวกับการการพัฒนาผู้เรียนโดยใช้กระบสนการวิจัยให้เกิดความเข้าใจให้มากขึ้น
							</span>
							<span
								v-if="score.sp[2][1] < 3"
								class="block"
							>
								- ควรฝึกการเลือกและนำผลการวิจัยมาใช้ในการพัฒนาผู้เรียนให้สอดคล้องกับปัญหาที่พบได้อย่างถูกต้อง
							</span>
							<span
								v-if="score.sp[2][2] < 1"
								class="block"
							>
								- ควรฝึกการวิเคราะห์จุดแข็ง จุดอ่อนของการนำผลการวิจัยมาใช้พัฒนาผู้เรียนเพื่อให้สามารถวางแผนการพัฒนาผู้เรียนได้อย่างเหมาะสม
							</span>
						</p>

						<div>D: การนำผลการวิจัยไปใช้ในการพัฒนาการเรียนการสอน</div>
						<p
							v-if="score.sp"
							class="pl-4"
						>
							<span
								v-if="score.sp[3][0] < 1"
								class="block"
							>
								- ควรทบทวนเนื้อหาเกี่ยวกับการพัฒนาการเรียนการสอนโดยใช้กระบวนการวิจัยให้เกิดความเข้าใจให้มากขึ้น
							</span>
							<span
								v-if="score.sp[3][1] < 2"
								class="block"
							>
								- ควรฝึกการเลือกและนำผลการวิจัยมาใช้ในการพัฒนาการเรียนการสอนให้สอดคล้องกับปัญหาที่พบได้อย่างถูกต้อง
							</span>
							<span
								v-if="score.sp[3][3] < 1"
								class="block"
							>
								- ควรฝึกการวิเคราะห์จุดแข็ง จุดอ่อนของการนำผลการวิจัยมาใช้พัฒนาการเรียนการสอนเพื่อให้สามารถวางแผนการพัฒนาผู้เรียนได้อย่างเหมาะสม
							</span>
						</p>

						<div>E: การแลกเปลี่ยนเรียนรู้ผลการวิจัย</div>
						<p
							v-if="score.sp"
							class="pl-4"
						>
							<span
								v-if="score.sp[4][0] < 1"
								class="block"
							>
								- ควรทบทวนเนื้อหาเกี่ยวกับการแลกเปลี่ยนเรียนรู้ทางการวิจัยให้เกิดความเข้าใจให้มากขึ้น
							</span>
							<span
								v-if="score.sp[4][1] < 1"
								class="block"
							>
								- ควรฝึกการนำผลการวิจัยเพื่อแลกเปลี่ยนความคิดเห็นกับผู้อื่น
							</span>
						</p>
					</div>
				</div>

				<!-- <div class="col-12 md:col-6 p-6 md:p-8">
					<Chart
						type="radar"
						:data="chartData2"
						:options="chartOptions"
					/>
					<p class="w-max mx-auto">
						A: ความเข้าใจ <br>
						B: นำไปใช้ประยุกต์ใช้ <br>
						C: วิเคราะห์ <br>
						D: สังเคราะห์/สร้างสรรค์ <br>
						E: ประเมิน
					</p>
				</div> -->
			</div>
    </div>
	</template>
</template>
